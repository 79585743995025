<template>
  <div class="particulars">
    <CompanyHeader titleurl="index" v-if="!isfromoa" />
    <div class="particulars-center">
      <div class="par-mid">
        <div class="wrap_content">
          <div class="conitem">
            <h3>
              <span class="cmpbg"></span>
              <span class="cmpname">新闻</span>
              <span class="more">
                <router-link :to="'/company/newslist?id=' + cid">
                  更多
                </router-link>
              </span>
            </h3>
            <div class="wnews">
              <ul>
                <li v-for="(item, i) in newlist" :key="i">
                  <div class="item-new">
                    <b class="newtitle" @click="getdetail(item.id)">{{
                      item.title
                    }}</b>
                    <span class="newdata">{{
                      item.createDate != null && item.createDate.length > 0
                        ? item.createDate.substring(5, 10)
                        : ''
                    }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="conitem" style=" margin-left: 20px;">
            <h3>
              <span class="cmpbg"></span>
              <span class="cmpname">联系我们</span>
              <span class="more">
                <router-link :to="'/company/contact'">
                  更多
                </router-link>
              </span>
            </h3>
            <div class="wcon">
              <div id="contactamaps" v-if="isamap">
                <el-amap
                  vid="amapcontactamaps"
                  :center="center"
                  :zoom="zoom"
                  class="amap-demo"
                >
                  <el-amap-marker
                    v-for="(marker, index) in markers"
                    :position="marker"
                    :key="index"
                  ></el-amap-marker>
                </el-amap>
              </div>
              <div class="contact">
                <span class="contact_tel">
                  联系电话：{{ cmpinfo.companyTel }}</span
                >

                <span class="contact_add">联系地址：{{ cmpinfo.address }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="wrap_content">
          <div class="conitem" style="width:100%">
            <h3>
              <span class="cmpbg"></span>
              <span class="cmpname">业务产品</span>
              <span class="more">
                <router-link :to="'/company/productlist?id=' + cid">
                  更多
                </router-link>
              </span>
            </h3>
            <div class="wcom">
              <ul>
                <li
                  v-for="(item, i) in productlist.slice(0, 3)"
                  :key="i"
                  class="container_row_selfthing"
                >
                  <router-link
                    target="_blank"
                    :title="item.title"
                    :to="'/product/detail?uniqueNo=' + item.uniqueNo"
                  >
                    <div class="item-img">
                      <el-image :src="item.headImg" fit="cover">
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                      <div class="product-title">
                        {{ item.title }}
                      </div>
                      <div class="product-price">
                        <template v-if="item.billingMode != ''">
                          <span class="rmb"> </span>
                          {{ item.billingVal }}
                        </template>
                        <template v-else>
                          <span class="rmb">¥</span>{{ item.price }}
                        </template>
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="wrap_content">
          <div class="conitem" style="width:100%;">
            <h3>
              <span class="cmpbg"></span>
              <span class="cmpname">我们的团队</span>
              <span class="more">
                <router-link :to="'/company/teamhonor?id=' + cid">
                  更多
                </router-link>
              </span>
            </h3>

            <div class="wcon" v-for="(jtem, j) in comTeamHonorList" :key="j">
              <div class="title intotitle" v-if="jtem.userList.length > 0">
                <h2>{{ jtem.certType }}</h2>
              </div>
              <ul>
                <li
                  v-for="(item, i) in jtem.userList"
                  :key="i"
                  class="container_row_selfthing"
                >
                  <div class="item-img">
                    <el-image
                      :src="item.standardAvatarOrigUrl"
                      alt=""
                      :onerror="defaultImg"
                    />
                    <div class="goodslook">
                      <b class="honorname">{{ item.userName }}</b>
                    </div>
                    <div class="product-title">
                      <span>{{ item.professionName }}</span>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
        <div class="wrap_content">
          <div class="conitem" style="width:100%;">
            <h3>
              <span class="cmpbg"></span>
              <span class="cmpname">我们的荣誉</span>
              <span class="more">
                <router-link :to="'/company/teamhonor?id=' + cid">
                  更多
                </router-link>
              </span>
            </h3>
            <div class="wcon">
              <ul>
                <li
                  v-for="(item, i) in cmpinfo.comElegantList"
                  :key="i"
                  class="container_row_selfthing"
                >
                  <div class="item-img">
                    <el-image :src="item.imgUrl" alt="" :onerror="defaultImg" />
                    <div class="goodslook">
                      <b class="honorname">{{ item.imgTitle }}</b>
                    </div>
                    <!-- <div class="product-title">
                      <span>{{ item.professionName }}</span>
                    </div> -->
                  </div>
                </li>
              </ul>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- 头部结束 -->
      <el-tabs v-model="activeName" type="card" v-if="false">
        <el-tab-pane label="工商信息" name="first">
          <div class="elegant-list clearfix">
            <table class="margin-t-1">
              <tr>
                <td>统一社会信用代码</td>
                <td>{{ cmpinfo.creditCode }}</td>
                <td>法定代表人</td>
                <td>{{ cmpinfo.legalPerson }}</td>
              </tr>
              <tr>
                <td>核准日期</td>
                <td>{{ cmpinfo.businessStart }}</td>
                <td>等级状态</td>
                <td>{{ cmpinfo.entRegStatusTxt }}</td>
              </tr>
              <tr>
                <td>所属行业</td>
                <td>{{ cmpinfo.entRegCategoryText }}</td>
                <td>成立日期</td>
                <td>{{ cmpinfo.establishDate }}</td>
              </tr>
              <tr>
                <td>公司类型</td>
                <td>{{ cmpinfo.entRegTypeTxt }}</td>
                <td>营业期限</td>
                <td>{{ cmpinfo.businessStart }}-{{ cmpinfo.businessEnd }}</td>
              </tr>
              <tr>
                <td>注册资本</td>
                <td>{{ cmpinfo.entRegCapital }}万元</td>
                <td>登记机关</td>
                <td>{{ cmpinfo.entRegAuth }}</td>
              </tr>
              <tr>
                <td>企业地址</td>
                <td colspan="3">
                  {{ cmpinfo.address }}
                </td>
              </tr>
              <tr>
                <td>经营范围</td>
                <td colspan="3">
                  {{ cmpinfo.businessScope }}
                </td>
              </tr>
            </table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="企业简介" name="second">
          <div class="elegant-list companyinfo clearfix">
            {{ cmpinfo.introduction }}
          </div>
        </el-tab-pane>
        <el-tab-pane label="企业证书" name="third">
          <div class="elegant-list clearfix">
            <ul>
              <li v-for="(item, index) in cmpinfo.comCertList" :key="index">
                <div class="imgwrap">
                  <el-image
                    style=""
                    :src="item.imgOUrl"
                    :preview-src-list="comCertList"
                  >
                  </el-image>
                </div>
                <p>{{ item.imgTitle }}</p>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <el-tab-pane label="企业荣誉" name="fourth">
          <div class="elegant-list clearfix">
            <ul>
              <li v-for="(item, index) in cmpinfo.comHonorList" :key="index">
                <div class="imgwrap">
                  <el-image
                    style=""
                    :src="item.imgOUrl"
                    :preview-src-list="comHonorList"
                  >
                  </el-image>
                </div>
                <p>{{ item.imgTitle }}</p>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <el-tab-pane label="团队风采" name="five">
          <div class="elegant-list clearfix">
            <ul>
              <li v-for="(item, index) in cmpinfo.comElegantList" :key="index">
                <div class="imgwrap">
                  <el-image
                    style=""
                    :src="item.imgOUrl"
                    :preview-src-list="comElegantList"
                  >
                  </el-image>
                </div>
                <p>{{ item.imgTitle }}</p>
              </li>
            </ul>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <Footer v-if="!isfromoa" />
  </div>
</template>
<script>
import { companygetcompanyinfo, getnewlist } from '@/api/company.js';
import {
  productgetcompanyproductlist,
  productgetcompanycustomlist
} from '@/api/product';
import CompanyHeader from '@/components/companyheader.vue';
import Footer from '@/components/footer.vue';
export default {
  name: 'App',
  components: {
    CompanyHeader,
    Footer
  },
  data() {
    return {
      newlist: [],
      center: [113.768678, 34.767936],
      markers: [[113.768678, 34.767936]],
      zoom: 15,
      isamap: true,
      isfromoa: false,
      comCertList: [],
      comHonorList: [],
      comElegantList: [],
      productlist: [],
      customproductlist: [],
      a: 1,
      activeName: 'first',
      productactiveName: 'first',
      cid: '',
      isloding: false,
      info: {},
      cmpinfo: {},
      isskip: 1,
      OfficeVisible: false,
      tableData: [],
      isInquriy: 1,
      type: '',
      pageIndex: 1,
      pageSize: 5,
      totalCount: 0,
      custompageIndex: 1,
      custompageSize: 12,
      customtotalCount: 0,
      comTeamHonorList: [],
      defaultImg:
        'this.src="' + require('@/assets/img/defaultheadpic.png') + '"'
    };
  },
  methods: {
    getdetail(val) {
      this.$router.push({
        path: '/company/newsdetail',
        query: { id: this.cid, newsid: val }
      });
    },
    handlecustomCurrentChange(item) {
      this.pageIndex = item;
      this.onproductgetcompanycustomlist();
    },
    handlecustomSizeChange(item) {
      this.pageSize = item;
      this.onproductgetcompanycustomlist();
    },
    handleCurrentChange(item) {
      this.pageIndex = item;
      //if (this.cid == 413) {
      this.onproductgetcompanycustomlist();
    },
    handleSizeChange(item) {
      this.pageSize = item;
      this.onproductgetcompanyproductlist();
    },
    scroll() {
      // 获取元素对屏幕上边的距离
      let top = parseInt(
        this.$refs.news[this.$refs.news.length - 1].getBoundingClientRect().top
      );
    },
    oncompanygetcompanyinfo() {
      companygetcompanyinfo({ companyid: this.cid }).then(res => {
        this.cmpinfo = res.data.result;
        // for (var i = 0; i < res.data.result.comHonorList.length; i++) {
        //   this.comHonorList.push(res.data.result.comHonorList[i].imgOUrl);
        // }
        // for (var i = 0; i < res.data.result.comElegantList.length; i++) {
        //   this.comElegantList.push(res.data.result.comElegantList[i].imgOUrl);
        // }
        for (var i = 0; i < res.data.result.comCertList.length; i++) {
          this.comCertList.push(res.data.result.comCertList[i].imgOUrl);
        }

        this.comTeamHonorList = this.cmpinfo.comTeamHonorList;
        this.comElegantList = this.cmpinfo.comElegantList;
        if (
          this.cmpinfo.lnglatAmap != null &&
          this.cmpinfo.lnglatAmap.length > 0
        ) {
          this.center = this.cmpinfo.lnglatAmap.split(',');
          this.markers = [this.center];
        } else {
          this.isamap = false;
        }
      });
    },
    ongetnewlist() {
      let paramnews = {
        categoryId: 300,
        sortRule: 0,
        companyId: this.cid,
        keyword: '',
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      };
      getnewlist(paramnews).then(res => {
        if (res.data.code == 1) {
          this.newlist = res.data.result.list;
        }
      });
    },
    onproductgetcompanyproductlist() {
      var that = this;
      productgetcompanyproductlist({
        companyid: this.cid,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }).then(res => {
        if (res.data.code == 1) {
          that.productlist = res.data.result.list;
          if (
            that.customproductlist.length == 0 &&
            that.productlist.length > 0
          ) {
            that.productactiveName = 'second';
          }
          that.totalCount = res.data.result.totalCount;
        }
      });
    },
    onproductgetcompanycustomlist() {
      var that = this;
      productgetcompanycustomlist({
        companyid: this.cid,
        pageIndex: this.custompageIndex,
        pageSize: this.custompageSize
      }).then(res => {
        if (res.data.code == 1) {
          that.customproductlist = res.data.result.list;
          that.customtotalCount = res.data.result.totalCount;
          that.onproductgetcompanyproductlist();
        }
      });
    }
  },
  mounted() {
    var id = this.$route.query.id;
    this.isfromoa = this.$route.query.fromoa == '1';
    this.cid = id;
    this.isInquriy = this.$route.query.isInquriy;
    this.info = {
      cmp: {},
      cert: {}
    };
    this.isloding = true;
    this.oncompanygetcompanyinfo();
    this.ongetnewlist();
    //if (this.cid == 413) {
    this.onproductgetcompanycustomlist();
    //} else {

    //}
  },
  created() {
    //console.warn('query.id', this.$route.query);
    if (this.$route.query.id === '409') {
      //言天下
      this.$router.push({
        path: `/company/ytx_detail`,
        query: { id: this.$route.query.id }
      });
    }
  }
};
</script>
<style scoped>
.items {
  max-width: 500px !important;
}
</style>
<style scoped>
.blue {
  background: #1478f0;
  color: white;
}
.op-06 {
  opacity: 0.6;
}
.margin-t-1 {
  margin-top: 15px;
}
.x-title h3 {
  display: inline-block;
  height: 20px;
  padding-left: 8px;
  margin: 0;
  border-left: 3px solid #1478f0;
  font-size: 18px;
  font-weight: 600;
  clear: left;
  margin: 20px 0;
}
.particulars {
  width: 100%;
  background: #f5f5f5;
}

.particulars-center {
  width: 100%;
  min-width: 1200px;
}
.particulars-center >>> .el-tabs__header {
  margin-bottom: 0;
}
.par-header {
  width: 100%;
  position: relative;
}
.par-header-content {
  box-sizing: border-box;
  margin: 0 auto;

  background-image: url('../../assets/img/topBg.jpg');
  padding: 40px 0 0px 0;
  min-width: 1200px;
}
.par-header-content .nav {
  color: #fff;
  margin-bottom: 5px;
  font-size: 14px;
}
.par-header-content a {
  color: #fff;
}

table {
  border: 1px solid #ddd;
  width: 1200px;
  max-width: 1200px;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
  margin: auto;
  display: table;
  box-sizing: border-box;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
td {
  display: table-cell;
  padding: 8px;
  line-height: 1.42857;
  vertical-align: top;
  box-sizing: border-box;
  background-color: #fcfcfc;
  color: #333;
  border: 1px solid #ddd;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
tr > td:nth-of-type(1) {
  width: 150px;
}
.Industry {
  background: #fff;
  width: 100%;
}
.Industry .Industry-center {
  width: 1200px;
  min-width: 1200px;
  background: #fff;
  margin: auto;
}
.Industry .margin-t-1 {
  margin-top: 15px;
}
.Industry table {
  border: 1px solid #ddd;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
}
.Industry table tr > td:nth-child(odd) {
  width: 150px;
}
.Industry table tr > td:nth-child(even) {
  width: 450px;
}
.Industry table td {
  padding: 8px;
  line-height: 1.42857;
  vertical-align: top;
  box-sizing: border-box;
  background-color: #fcfcfc;
  color: #333;
  border: 1px solid #ddd;
}

.valuer {
  width: 100%;
  background: #fff;
  font-size: 14px;
}
.valuer .valuer-content {
  width: 1200px;
  min-width: 1200px;
  background: #fff;
  margin: auto;
}
.valuer .valuer-name-title {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
}
.valuer div {
  width: 20%;
  height: 100%;
  border: 1px solid #ddd;
  background: #f2f9fc;
  text-align: center;
  line-height: 50px;
  font-size: 14px;
}

.valuer .valuer-picture {
  width: 100%;
  height: auto;
  display: flex;

  flex-wrap: wrap;
}
.valuer .content {
  text-align: center;
  height: 120px;
  padding: 10px;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
}
.content-img {
  width: 100%;
}
.content-img img {
  width: 80px;
}
.elegant-list li {
  float: left;
  width: 280px;
  height: 280px;
  margin-right: 7px;
  cursor: pointer;
}
.elegant-list.companyinfo {
  padding: 20px 0;
  font-size: 15px;
}
.elegant-list li div.imgwrap {
  width: 280px;
  height: 208px;
  position: relative;
  padding: 11px;
}

.elegant-list li div.imgwrap >>> .el-image {
  width: 280px;
  height: 208px;
  border: solid 1px #e6e6e6;
}

.elegant-list li p {
  margin-top: 10px;
  color: #333;
  text-align: center;
  font-size: 12px;
  height: 24px;
  line-height: 24px;
}
.content-xq {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
}

.cooperative-content {
  width: 1200px;
  min-width: 1200px;
  background: #fff;
  margin: auto;
}
.cooperative-content-title {
  width: 100%;
  display: flex;
}
.cooperative-table-td {
  width: 33.33%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #ddd;
  display: flex;
  font-size: 14px;
}
.cooperative .nameicon {
  width: 20%;
}
.cooperative img {
  border-radius: 3px;
}

.cooperative .nametitle {
  width: 80%;
  border-left: 1px solid #ddd;
}

.cooperative-content-xq {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ddd;
  box-sizing: border-box;
}
.cooperative-table-td {
  width: 33.33%;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  box-sizing: border-box;
  border-top: 0;
  display: flex;
  font-size: 14px;
}
.cooperative-table-td .nameicon {
  width: 20%;
  padding: 10px;
  box-sizing: border-box;
}
.cooperative-table-td img {
  width: 100%;
}

.cooperative-table-td .nametitle {
  width: 80%;
  border-left: 1px solid #ddd;
}

.product {
  width: 100%;
  background: #fff;
}
.product .product-content {
  width: 1200px;
  min-width: 1200px;
  background: #fff;
  margin: auto;
}
.product .store-content {
  width: 100%;
  display: flex;
}
.box-card {
  width: 15%;
}
.item {
  padding: 18px 0;
}

.store-list {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.container_row {
  margin: 0px auto;
}
.container_row_selfthing {
  width: 160px;
  /* height: 263px; */
  background-color: #fff;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.2s linear;
}

.container_row_selfthingimg.container_row_img {
  width: 100%;
  height: 220px;
}

.container_row_spec:hover {
  transform: translate3d(0, -2px, 0);
}
.container_row_img {
  cursor: pointer;
}
/* .container_row_spec:nth-child(5),
.container_row_spec:nth-child(10) {
  margin-right: 0px !important;
} */
.container_row_name {
  color: #666;
  height: 35px;
  font-size: 12px;
  width: 100%; /* margin-top: -4px; */
  background-color: #fff;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 40px;
}
.container_row_name img {
  position: relative;
  top: 5px;
  margin-left: 9px;
}
.container_row_price {
  background-color: #fff;
  font-size: 20px;
  padding: 0 10px;
  color: #b31e22;
}
.container_row_price .rmb {
  font-size: 12px;
  color: #666;
}
.container_row_spec.custom {
  height: 200px;
  width: 180px;
}
.container_row_price .mode {
  font-size: 12px;
  color: #666;
}

.container_row_map {
  width: 100%;
  margin-top: -31px;
  height: 28px;
  background-color: #1c1c1c;
  opacity: 0.76;
  bottom: 0;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 28px;
  padding-left: 9px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
}

.pricebox .price {
  color: #ff0000cc;
  font-size: 17px;
  font-weight: bold;
  padding: 0 !important;
  margin: 0 !important;
  height: 30px;
  line-height: 30px;
}

.pricebox2 .price {
  color: #ff0000cc;
  font-size: 14px;
  font-weight: normal;
  padding: 0 !important;
  margin: 0 !important;
  height: 30px;
  line-height: 30px;
}

.pricebox .price .rmb {
  font-size: 12px;
}

.pricebox .delprice {
  font-size: 12px;
  color: #999;
  text-decoration: line-through;
  margin-left: 16px;
  font-weight: normal;
}

.clearfix {
  clear: both;
}
.page {
  margin-top: 30px;
  text-align: center;
}
.navmenu {
  max-width: 1200px;
  height: 50px;
  margin: 0 auto;
  border-top: 3px #db2242 solid;
  background: #fff;
}
.navwrap {
  height: 30px;
  width: 1200px;
  margin: 0 auto;
  line-height: 30px;
  padding: 10px 0;
  border: 1px #eee solid;
}
.firnav {
  position: relative;
  padding-right: 3.75vw;
  font-size: 0;
  white-space: nowrap;
  text-align: right;
}
.firnav .firitem {
  vertical-align: top;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-left: 18px;
  margin-right: 18px;
  margin-left: 1.25vw;
  margin-right: 1.25vw;
}
.firnav .link1 {
  display: block;
  font-size: 16px;
  display: inline-block;
  line-height: 30px;
  height: 30px;
  color: #222;
  font-size: 18px;
  margin: 0 10px;
}
.firnav .firitem.on .link1,
.firnav .firitem:hover .link1 {
  background: #fff;
  color: #db2242;
  border-radius: 30px;
}
.par-mid {
  max-width: 1200px;
  margin: 0 auto;
}
.par-mid .wrap_content {
  margin: 20px 0;
  min-height: 180px;
  clear: both;
  display: flex;
}
.par-mid .wrap_content .conitem {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  width: 600px;
}

.par-mid .wrap_content.product {
  min-height: 280px;
}
.par-mid .wrap_content h3 {
  position: relative;
}
.par-mid .wrap_content h3 .cmpname {
  font-weight: 600;
  font-size: 18px;
  color: #000;
}

.par-mid .wrap_content .more {
  top: 2px;
  right: 0;
  font-size: 16px;
  position: absolute;
}
.par-mid .wrap_content .more a {
  color: #26abe3;
  font-size: 14px;
  font-weight: normal;
}

.wnews {
  margin-top: 20px;
}
.wnews .item-new {
  padding: 15px 2px;
  border-bottom: 1px #c9c9c9 dashed;
  color: #3e3a39;
}
.wnews .newtitle {
  width: 520px;
  font-size: 13px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
.wnews .newtitle:hover {
  text-decoration: underline;
  color: #4169e1;
}
.wnews .newdata {
  font-size: 13px;
  float: right;
  color: #b7b7b7;
}

.wcon ul li,
.wcom ul li {
  float: left;
  cursor: pointer;
}
.wcon ul li {
  margin-bottom: 50px;
}
.wcom {
  padding: 5px 10px;
  margin-top: 20px;
}
.wcon {
  padding: 5px 10px;
  padding-left: 0px;
}
.wcom .item-img {
  width: 100px;
  height: 120px;
  text-align: center;
}
.wcom .item-img img {
  border-radius: 15px;
  width: 64px;
  height: 64px;
}
.wcom .product-title {
  color: #707070;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.wcom .honorname {
  color: #696969;
}
.wcon .item-img {
  width: 180px;
  height: 260px;
  text-align: center;
  margin: 0 16px;
}
.wcon .item-img .el-image {
  width: 160px;
  height: 180px;
  border-radius: 40px 0 40px 0;
}
.wcon .product-title span {
  line-height: 20px;
  font-size: 13px;
  color: #707070;
  width: 80%;
}
.wcon .honorname {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #222222;
}
.clearfix {
  clear: both;
}
.contact {
  margin-left: 10px;
  font-size: 15px;
  color: #3d3938;
  width: 182px;
  float: left;
  margin-top: 10px;
}
.contact span {
  display: block;
  width: 100%;
}
#contactamaps {
  margin: 10px;
  width: 336px;
  height: 300px;
  margin-left: 0px;
  float: left;
}

.container_row_selfthing .company-logo .el-image {
  width: 180px;
  height: 180px;
  border-radius: 10%;
  border: 1px solid #ededed;
}
.container_row_selfthing .company-logo >>> .image-slot {
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #ededed;
}

.container_row_selfthing .item-img {
  width: 170px;
  height: 170px;
  text-align: center;
  margin: 18px 5px;
}
.container_row_selfthing .item-img .el-image {
  width: 160px;
  height: 160px;
  border-radius: 10%;
  border: 1px solid #ededed;
}
.container_row_selfthing .item-img >>> .image-slot {
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #ededed;
}

.container_row_selfthing .company-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.container_row_selfthing .product-title {
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center; */
}
.container_row_selfthing .product-price {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #ff1100;
}
.cmpbg {
  font-size: 24px;
  color: #000000;
  font-weight: normal;
  padding: 10px 0 5px 0;
  font-weight: 600;
  background-image: linear-gradient(
    90deg,
    #29ace2 0%,
    rgb(255 255 255 / 0%) 100px
  );
  width: 100%;
  position: absolute;
  display: block;
  top: 12px;
  z-index: 0;
}
.cmpname {
  position: relative;
  z-index: 1;
}
.title {
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.title h2 {
  display: inline-block;
  font-family: PingFangSC-Semibold, Microsoft YaHei;
  font-size: 14px;
  color: #333;
  line-height: 32px;
  font-weight: 600;
  position: relative;
  padding-left: 10px;
}
.title.intotitle h2::before {
  content: '';
  position: absolute;
  width: 3px;
  height: 19px;
  border-radius: 2px;
  background-color: #268eff;
  left: 0;
  top: 8px;
}
</style>
